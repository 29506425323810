/*
Document   : jquery.pnotify.default.css
Created on : Nov 23, 2009, 3:14:10 PM
Author     : Hunter Perrin
Version    : 1.2.0
Link       : http://pinesframework.org/pnotify/
Description:
	Default styling for Pines Notify jQuery plugin.
*/
/* -- Notice */
.ui-pnotify {
top: 25px;
right: 25px;
position: absolute;
height: auto;
/* Ensures notices are above everything */
z-index: 9999;
}
/* Hides position: fixed from IE6 */
html > body .ui-pnotify {
position: fixed;
}
.ui-pnotify .ui-pnotify-shadow {
-webkit-box-shadow: 0px 2px 10px rgba(50, 50, 50, 0.5);
-moz-box-shadow: 0px 2px 10px rgba(50, 50, 50, 0.5);
box-shadow: 0px 2px 10px rgba(50, 50, 50, 0.5);
}
.ui-pnotify-container {
background-position: 0 0;
padding: .8em;
height: 100%;
margin: 0;
}
.ui-pnotify-sharp {
-webkit-border-radius: 0;
-moz-border-radius: 0;
border-radius: 0;
}
.ui-pnotify-closer, .ui-pnotify-sticker {
float: right;
margin-left: .2em;
}
.ui-pnotify-title {
display: block;
margin-bottom: .4em;
}
.ui-pnotify-text {
display: block;
}
.ui-pnotify-icon, .ui-pnotify-icon span {
display: block;
float: left;
margin-right: .2em;
}
/* -- History Pulldown */
.ui-pnotify-history-container {
position: absolute;
top: 0;
right: 18px;
width: 70px;
border-top: none;
padding: 0;
-webkit-border-top-left-radius: 0;
-moz-border-top-left-radius: 0;
border-top-left-radius: 0;
-webkit-border-top-right-radius: 0;
-moz-border-top-right-radius: 0;
border-top-right-radius: 0;
/* Ensures history container is above notices. */
z-index: 10000;
}
.ui-pnotify-history-container .ui-pnotify-history-header {
padding: 2px;
}
.ui-pnotify-history-container button {
cursor: pointer;
display: block;
width: 100%;
}
.ui-pnotify-history-container .ui-pnotify-history-pulldown {
display: block;
margin: 0 auto;
}