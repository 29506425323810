
/*
 * Table
 */
table.dataTable {
	margin: 0 auto;
	clear: both;
	width: 100%;
}

table.dataTable thead th {
	padding: 3px 18px 3px 10px;
	border-bottom: 1px solid black;
	font-weight: bold;
	cursor: pointer;
	*cursor: hand;
}

table.dataTable tfoot th {
	padding: 3px 18px 3px 10px;
	border-top: 1px solid black;
	font-weight: bold;
}

table.dataTable td {
	padding: 3px 10px;
}

table.dataTable td.center,
table.dataTable td.dataTables_empty {
	text-align: center;
}
/*
table.dataTable tr.odd { background-color: #E2E4FF; }
table.dataTable tr.even { background-color: white; }

table.dataTable tr.odd td.sorting_1 { background-color: #D3D6FF; }
table.dataTable tr.odd td.sorting_2 { background-color: #DADCFF; }
table.dataTable tr.odd td.sorting_3 { background-color: #E0E2FF; }
table.dataTable tr.even td.sorting_1 { background-color: #EAEBFF; }
table.dataTable tr.even td.sorting_2 { background-color: #F2F3FF; }
table.dataTable tr.even td.sorting_3 { background-color: #F9F9FF; }
*/

/*
 * Table wrapper
 */
.dataTables_wrapper {
	position: relative;
	clear: both;
	*zoom: 1;
}


/*
 * Page length menu
 */
.dataTables_length {
	float: left;
}


/*
 * Filter
 */
.dataTables_filter {
	float: right;
	text-align: right;
}


/*
 * Table information
 */
.dataTables_info {
	clear: both;
	//float: left;
	margin-top:10px;
	font-weight: bold;
}


/*
 * Pagination
 */
.dataTables_paginate {
	float: right;
	text-align: right;
}

/* Two button pagination - previous / next */
.paginate_disabled_previous,
.paginate_enabled_previous,
.paginate_disabled_next,
.paginate_enabled_next {
	height: 19px;
	float: left;
	cursor: pointer;
	*cursor: hand;
	color: #111 !important;display: none;
}
.paginate_disabled_previous:hover,
.paginate_enabled_previous:hover,
.paginate_disabled_next:hover,
.paginate_enabled_next:hover {
	text-decoration: none !important;
}
.paginate_disabled_previous:active,
.paginate_enabled_previous:active,
.paginate_disabled_next:active,
.paginate_enabled_next:active {
	outline: none;
}

.paginate_disabled_previous,
.paginate_disabled_next {
	color: #666 !important;
}
.paginate_disabled_previous,
.paginate_enabled_previous {
	padding-left: 23px;
}
.paginate_disabled_next,
.paginate_enabled_next {
	padding-right: 23px;
	margin-left: 10px;
}

.paginate_enabled_previous { background: url('/images/back_enabled.png') no-repeat top left; }
.paginate_enabled_previous:hover { background: url('/images/back_enabled_hover.png') no-repeat top left; }
.paginate_disabled_previous { background: url('/images/back_disabled.png') no-repeat top left; }

.paginate_enabled_next { background: url('/images/forward_enabled.png') no-repeat top right; }
.paginate_enabled_next:hover { background: url('/images/forward_enabled_hover.png') no-repeat top right; }
.paginate_disabled_next { background: url('/images/forward_disabled.png') no-repeat top right; }

/* Full number pagination */
.paging_full_numbers {
	height: 22px;
	line-height: 22px;
}
.paging_full_numbers a:active {
	outline: none
}
.paging_full_numbers a:hover {
	text-decoration: none;
}

.paging_full_numbers a.paginate_button,
.paging_full_numbers a.paginate_active {
	//border: 1px solid #aaa;
	border-left: none;
	//-webkit-border-radius: 5px;
	//-moz-border-radius: 5px;
	//border-radius: 5px;
	padding: 2px 5px;
	//margin: 0 3px;
	cursor: pointer;
	*cursor: hand;
	color: #333 !important;
}

.paging_full_numbers a.paginate_button.first,
.paging_full_numbers a.paginate_active.first {
	border-radius: 3px 0 0 3px;
	//border: 1px solid #aaa;
}
.paging_full_numbers a.paginate_button.last,
.paging_full_numbers a.paginate_active.last {
	border-radius: 0 3px 3px 0;
	//border-left: 1px solid #aaa;
}

.paging_full_numbers a.paginate_button {
	position: relative;
	float: left;
	padding: 6px 12px;
	margin-left: -1px;
	line-height: 1.428571429;
	text-decoration: none;
	background-color: #f9f9f9;
	border: 1px solid #ddd;
}

.paging_full_numbers a.paginate_button:hover {
	background-color: #eee;
	text-decoration: none !important;
}

.paging_full_numbers a.paginate_active {
	position: relative;
	padding: 6px 12px;
	margin-left: -1px;
	line-height: 1.428571429;
	text-decoration: none;
	border: 1px solid #ddd;
	z-index: 2;
	color: #fff!important;
	cursor: default;
	background-color: #428bca;
	border-color: #428bca;
	float:left;
}


/*
 * Processing indicator
 */
.dataTables_processing {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 250px;
	height: 30px;
	margin-left: -125px;
	margin-top: -15px;
	padding: 14px 0 2px 0;
	border: 1px solid #ddd;
	text-align: center;
	color: #999;
	font-size: 14px;
	background-color: white;
}


/*
 * Sorting
 */
.sorting { background: url('/images/sort_both.png') no-repeat center right; }
.sorting_asc { background: url('/images/sort_asc.png') no-repeat center right; }
.sorting_desc { background: url('/images/sort_desc.png') no-repeat center right; }

.sorting_asc_disabled { background: url('/images/sort_asc_disabled.png') no-repeat center right; }
.sorting_desc_disabled { background: url('/images/sort_desc_disabled.png') no-repeat center right; }
 
table.dataTable thead th:active,
table.dataTable thead td:active {
	outline: none;
}


/*
 * Scrolling
 */
.dataTables_scroll {
	clear: both;
}

.dataTables_scrollBody {
	*margin-top: -1px;
	-webkit-overflow-scrolling: touch;
}

